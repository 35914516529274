<template>
  <div class="page-account">
    <div class="page-account-header"></div>
    <div class="page-account-container">
      <SocialSign></SocialSign>
      <div class="xz-login">
        <el-form :model="regForm" :rules="rules" ref="regForm">
          <el-form-item prop="username"
            ><el-input
              placeholder="请输入用户名"
              prefix-icon="el-icon-user"
              v-model="regForm.username"
            >
            </el-input
          ></el-form-item>
          <el-form-item prop="password"
            ><el-input
              placeholder="至少六位密码，区分大小写"
              prefix-icon="el-icon-lock"
              v-model="regForm.password"
              show-password
            >
            </el-input
          ></el-form-item>
          <el-form-item prop="checkPass"
            ><el-input
              placeholder="确认密码"
              prefix-icon="el-icon-lock"
              v-model="regForm.checkPass"
              show-password
            >
            </el-input
          ></el-form-item>
          <el-form-item prop="mobile"
            ><el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-phone"
              v-model="regForm.mobile"
            >
            </el-input
          ></el-form-item>
          <el-form-item prop="captcha">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-input
                  placeholder="请输入验证码"
                  prefix-icon="el-icon-set-up"
                  v-model="regForm.captcha"
                >
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-button>获取验证码</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button
              class="bigbtn"
              type="primary"
              @click="onSubmit('regForm')"
              >立即注册</el-button
            >
          </el-form-item>
        </el-form>
        <div class="page-account-to-login">
          <router-link to="/login">使用已有账户登录</router-link>
        </div>
      </div>
    </div>
    <PageFooter></PageFooter>
  </div>
</template>
<script>
import SocialSign from './components/social-sign.vue';
import PageFooter from './components/page-footer.vue';

export default {
  name: 'register',
  components: { SocialSign, PageFooter },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.regForm.checkPass !== '') {
          this.$refs.regForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      regForm: {
        username: '',
        password: '',
        checkPass: '',
        mobile: '',
        captcha: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  methods: {
    onSubmit (regForm) {
      this.$refs[regForm].validate((valid) => {
        if (valid) {
          this.$router.push({ path: '/login' });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.page-account {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.page-account-header {
  text-align: right;
  position: fixed;
  top: 16px;
  right: 24px;
}
.page-account-container {
  flex: 1;
  padding: 32px 0;
  text-align: center;
  width: 384px;
  margin: 0 auto;
}
.xz-login {
  .page-account-auto-login {
    margin-bottom: 24px;
    text-align: left;
  }
  .page-account-auto-login a {
    float: right;
  }
  .page-account-other {
    margin: 24px 0;
    text-align: left;
    img {
      width: 24px;
      margin-left: 16px;
      cursor: pointer;
      vertical-align: middle;
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
    }
    .page-account-to-login {
      text-align: center;
      margin-top: 16px;
    }
  }
}
</style>
